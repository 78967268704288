import { appWithTranslation } from 'next-i18next';
import { Provider } from 'react-redux';

import 'global-styles.css';
import 'material-icons/iconfont/material-icons.css';
import 'public/scss/theme.scss';
import 'react-datepicker/dist/react-datepicker.css';

import store from 'app/store';
import WithAuth from 'components/Auth/WithAuth';
import getLayout from 'components/Layout/LayoutConfiguration';
import nextI18NextConfig from 'next-i18next.config';

function MyApp({ Component, pageProps, router }) {
  const layout = getLayout(router.pathname);

  return (
    <Provider store={store}>
      <WithAuth router={router}>
        {layout({ children: <Component {...pageProps} /> })}
      </WithAuth>
    </Provider>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
