import axios from 'axios';

import { OPS_PORTAL_FQDN } from '@/services/connections.service';
import { getAuthToken } from '@/services/identity.service';

axios.defaults.headers = {
  'content-type': 'application/json',
  'x-origin-domain': OPS_PORTAL_FQDN
};

export const getWithoutAuth = (url) =>
  new Promise((resolve) => {
    axios
      .get(url)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, error: ex.message });
      });
  });

export const postWithoutAuth = (url, entity) =>
  new Promise((resolve) => {
    axios
      .post(url, entity)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        console.log(ex);
        resolve({ status: false, message: ex.message });
      });
  });

const getOptionsForWithAuth = () => {
  const token = getAuthToken();
  const headers = {
    'x-access-token': token
  };
  return { headers, withCredentials: true };
};

export const getWithAuth = (url) => {
  const withAuthOptions = getOptionsForWithAuth();

  return new Promise((resolve) => {
    axios
      .get(url, withAuthOptions)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, error: ex.message });
      });
  });
};

export const postWithAuth = (url, entity) =>
  new Promise((resolve) => {
    const withAuthOptions = getOptionsForWithAuth();
    axios
      .post(url, entity, withAuthOptions)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.message });
      });
  });

export const putWithAuth = (url, data) => {
  const withAuthOptions = getOptionsForWithAuth();
  return new Promise((resolve) => {
    axios
      .put(url, data, withAuthOptions)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({
          status: false,
          message: ex.response?.data?.message || ex.message
        });
      });
  });
};

export const patchWithAuth = (url, entity) =>
  new Promise((resolve) => {
    const withAuthOptions = getOptionsForWithAuth();
    axios
      .patch(url, entity, withAuthOptions)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.message });
      });
  });

export const deleteWithAuth = (url, entity) =>
  new Promise((resolve) => {
    const withAuthOptions = getOptionsForWithAuth();
    axios
      .delete(url, {
        ...withAuthOptions,
        data: entity
      })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.message });
      });
  });

const interpolatePath = ({ pathname, pathParams }) =>
  Object.entries(pathParams).reduce(
    (template, [key, value]) => template.replace(`:${key}`, value ?? ''),
    pathname
  );

const buildQueryString = ({ queryParams }) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return queryString ? `?${queryString}` : '';
};

export const getURLPath = ({ pathname, pathParams = {}, queryParams = {} }) => {
  const interpolatedPath = interpolatePath({ pathname, pathParams });
  const queryString = buildQueryString({ queryParams });
  return `${interpolatedPath}${queryString}`;
};
